import { Button, Result, Space } from 'antd';
import SpaceDetail from 'components/channel/SpaceDetail';
import ChannelsList, { ChannelActions } from 'components/ChannelsList';
import BriefIcon from 'components/commons/BriefIcon';
import CommonTitle from 'components/commons/CommonTitle';
import { Debug } from 'components/commons/DebugComponent';
import JsonLog from 'components/commons/JsonLog';
import Spacer from 'components/commons/Spacer';
import CardContainer from 'components/layout/CardContainer';
import Loading from 'components/layout/Loading';
import Logo from 'components/layout/Logo';
import MainContainer from 'components/layout/MainContainer';
import SeoComponent from 'components/layout/SeoComponent';
import TopBar from 'components/layout/TopBar';
import { navigateUrl } from 'constants/helpers';
import CommonOverlayComponent from 'constants/Modals';
import ROUTES from 'constants/Routes';
import fetchJson from 'lib/fetchJson';
import withSession from 'lib/session';
import { GetServerSideProps, NextApiRequest } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import nookies, { parseCookies, destroyCookie } from 'nookies';
import React, { useEffect, useMemo, useState } from 'react';
import ServiceAuthentication from 'services/Auth';
import LocalStorage from 'services/LocalStorage';
import { getReqSession } from 'services/server/ServerHelpers';
import ServiceChannels from 'services/ServiceChannels';
import { useSessionUser } from 'store/hooks';
import { BriefSpace, Channel } from 'store/types';
import COLORS from 'styles/Colors';


const CommonTour = dynamic(import('components/commons/CommonTour'), {
  ssr: false
});

const HomePage = ({
  space: initialSpace,
  subdomain,
  channels: initialChannels,
  cookies = {},
  headers = {},
  autologin = false
}: {
  space: BriefSpace;
  subdomain: string;
  channels: Channel[];
  cookies: any;
  headers: any;
  autologin?: boolean;
}) => {
  const store = useSessionUser();
  const { me, setMe, signOut } = store;
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const [channels, setChannels] = useState<Channel[]>(initialChannels);
  const [space, setSpace] = useState<BriefSpace>(initialSpace);

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const [isTourOpen, setIsTourOpen] = useState(false);

  //TODO: check is my Space
  const isMyPage = useMemo(() => {

    return me?.uuid && me?.rule > 1
  }, [me, space, channels]);




  const steps = useMemo(
    () => [
      {
        selector: '#channelDescription',
        content: 'Add your Space Desscription'
      },
      {
        selector: '.channelImage .ant-upload-drag-container',
        content: 'Add your cover image'
      },
      {
        selector: '.briefIcon.add',

        content: ({ goTo }) => (
          <div className="textCenter">
            Add your first Channel
            <br />
            <br />
            <Button type="primary" onClick={() => setIsTourOpen(false)}>
              Begin
            </Button>
          </div>
        )
      }
    ],
    []
  );

  useEffect(() => {
    if (autologin) {
      doAutoLogin();
    }
  }, []);

  useEffect(() => {
    if (!channels.length && me && isMyPage) {
      if (!LocalStorage.getItem('wizardWelcomeDone')) {
        LocalStorage.setItem('wizardWelcomeDone', 1);
        setShowWelcomeModal(true);
      }
    }
  }, [isMyPage, channels]);

  useEffect(() => {
    //if (isMyPage) {
    refreshSpace();
    // }
  }, [isMyPage]);

  const doAutoLogin = async () => {
    debugger;
    console.log('🚀 ~ doAutoLogin ~ doAutoLogin');
    const cookies = parseCookies();
    console.log({ cookies });
    destroyCookie(null, 'subdomain', { path: '/' });
    if (cookies.Authorization) {
      const user = await ServiceAuthentication.profile();

      //set user into session
      await fetchJson('/api/user', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: { user, subdomain }
      });

      setMe(user);
      LocalStorage.setItem('me', user);
      //LocalStorage.setItem('subdomain', subdomain);
    }
  };

  const refreshSpace = async () => {
    setLoading(true);
    ServiceChannels.home({})
      .then(({ channels, space }) => {
        setChannels(channels);
        setSpace(space);
      })
      .catch(err => {
        debugger;
      })
      .finally(() => setLoading(false));
  };
  const onClickedChannel = (channel: Channel) => {
    router.push(ROUTES.business.getChannelPage(channel.uuid));
  };

  const addChannel = () => {
    setLoading(true);
    ServiceChannels.create({})
      .then((newChannel) => {
        console.log('🚀 ~ ServiceChannels.list', newChannel);
        router.push(ROUTES.business.getChannelPage(newChannel.uuid));
      })
      .finally(() => setLoading(false));
  };

  const onAction = (action: ChannelActions) => {
    switch (action) {
      case 'delete':
        refreshSpace();
        break;

      case 'edit':
        break;

      default:
        break;
    }
  };

  const closeWelcome = () => {
    if (!LocalStorage.getItem('wizardChannelDone')) {
      LocalStorage.setItem('wizardChannelDone', 1);
      setIsTourOpen(true);
    }
    setShowWelcomeModal(false);
  };

  return (
    <MainContainer id="HomePage" topBar={<TopBar />}>
      <SeoComponent space={space} />

      <CardContainer>
        {space ? (
          <React.Fragment>
            <SpaceDetail space={space} isMyPage={isMyPage}>
              {isMyPage && (
                <BriefIcon
                  icon="Add"
                  title="Create new Channel"
                  color={COLORS.main}
                  onClick={addChannel}
                  className="addIconFill"
                />
              )}
            </SpaceDetail>
            <Debug>
              <Button onClick={refreshSpace}>refreshSpace</Button>
            </Debug>

            <Spacer margin={20} className="desktop" />
            <Loading spinning={loading}>
              <ChannelsList
                ownChannel={isMyPage}
                channels={channels}
                onClickedChannel={onClickedChannel}
                onAction={onAction}
              />
            </Loading>
          </React.Fragment>
        ) : (
          <Result
            status="warning"
            title={`There is no Subdomain ${subdomain}`}
            extra={
              <Space>
                <Button
                  type="primary"
                  key="console"
                  onClick={() => navigateUrl('https://voicebrief.app/signin/')}>
                  Go SignIn
                </Button>
                <Button
                  type="primary"
                  key="console"
                  onClick={() => navigateUrl('https://voicebrief.app/signup/')}>
                  Go Signup
                </Button>
              </Space>
            }
          />
        )}
      </CardContainer>
      <JsonLog src={{ cookies, headers, subdomain, space, channels }} title="SPACE" />
      <JsonLog src={store} />

      <CommonOverlayComponent
        visible={showWelcomeModal}
        cancelText="Create your first channel"
        closable={false}
        className="welcomeModal"
        maskClosable={false}
        onCancel={closeWelcome}>
        <Space className="fullWidth" direction="vertical" align="center">
          <Logo color="logo" />
          <CommonTitle level={2} style={{ fontWeight: 'bold' }}>
            Welcome to Brief!
          </CommonTitle>
        </Space>
      </CommonOverlayComponent>

      <CommonTour visible={isTourOpen} steps={steps} onClose={() => setIsTourOpen(false)} />
    </MainContainer>
  );
};

export default HomePage;

export const getServerSideProps: GetServerSideProps = withSession(async function (ctx) {
  const { res, params, query } = ctx;
  const req: NextApiRequest = ctx.req;

  const querySubdomain = query?.subdomain;
  const autologin = query?.autologin || false;

  console.log('🚀 ~ req', req.headers.host);

  nookies.destroy(ctx, 'subdomain', { path: '/' })
  console.log({ params, query });
  const cookies = nookies.get(ctx);
  console.log('🚀 ~ getServerSideProps ~ cookies', cookies);
  const { Authorization, deviceToken, subdomain: sessionSubdomain } = getReqSession(ctx.req);

  const subdomain = querySubdomain || sessionSubdomain;
  console.log({ Authorization, deviceToken, subdomain });


  try {
    //const subdomain = getSubdomainFromHost(req.headers.host);
    const { channels, space } = await ServiceChannels.business.list({
      headers: { subdomain }
    });
    return {
      props: {
        channels,
        space,
        headers: req.headers,
        subdomain,
        autologin,
        cookies
      }
    };
  } catch (e) {
    return {
      props: {
        channels: [],
        space: null,
        headers: req.headers,
        subdomain,
        autologin,
        cookies
      }
    };
  }
});
