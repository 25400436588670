import { EllipsisOutlined, ExclamationCircleOutlined, MenuOutlined } from '@ant-design/icons';

import { Button, Dropdown, Menu, Modal, Space, Switch, Table, Tag, Typography } from 'antd';
import DragAndDrop from 'components/commons/DragAndDrop';
import DraggableRow from 'components/commons/DraggableRow';

import update from 'immutability-helper';
import React, { FC, useEffect, useState, useMemo } from 'react';
import ServiceChannels from 'services/ServiceChannels';
import { Channel, ChannelStatus, viewmode } from 'store/types';
import RowAvatarImage from 'components/commons/ChannelImg';
import DragableCard from 'components/commons/DraggableCard';
import ShareComponent from 'components/commons/ShareComponent';
import SwitchStatus from './commons/SwitchStatus';
import { formatTimeStamp } from 'constants/date.helpers';
import classNames from 'classnames';
import JsonLog from './commons/JsonLog';
import NoItems from './commons/NoItems';
import { ModalConfirm } from 'constants/Notifications';
import RuleTag from './commons/RuleTag';

const { confirm } = Modal;

export type ChannelActions = 'edit' | 'delete' | 'status';
interface ChannelsListProps {
  channels?: Channel[];
  ViewMode?: viewmode;
  onAction?: (action: ChannelActions) => any;
  onClickedChannel?: Function;
  ownChannel?: boolean;
}
const ChannelsList: FC<ChannelsListProps> = ({
  channels = [],
  ViewMode = 'list',
  onAction,
  onClickedChannel,
  ownChannel = false
}) => {
  const [tableData, setTableData] = useState(channels);

  useEffect(() => {
    console.log('Effect ChannelsList', channels);
    setTableData(channels);
  }, [channels]);

  const goToChannel = (channel: Channel) => {
    if (onClickedChannel) onClickedChannel(channel);
  };


  const deleteChannel = async (channel: Channel) => {
    ModalConfirm({
      title: 'Are you sure delete this Channel?',
      content: `Delete Channel ${channel.title} ?`,
      okText: 'Delete'
    }).then((confirmed) => {
      if (confirmed) {
        ServiceChannels.delete({ channelUUID: channel.uuid }).then((response) => {
          onAction && onAction('delete');
        });
      }
    });
  };

  const columns = useMemo(
    () => [
      ...(ownChannel
        ? [
          {
            title: '',
            dataIndex: 'id',

            className: 'dragColumn',
            //responsive: ['lg'],
            render: () => (
              <div className="hoverRow">
                <img src="/drag.svg" />
              </div>
            )
          }
        ]
        : []),
      {
        title: 'Channel',
        className: ' imageColumn',
        key: 'image',
        render: (channel: Channel) => (
          <div onClick={() => goToChannel(channel)}>
            <RowAvatarImage channel={channel} />
          </div>
        )
      },
      {
        title: 'Description',
        key: 'description',
        className: 'textColumn',
        render: (channel: Channel) => (
          <div className="ChannelDescription">
            <div className="flexRow">
              {ownChannel && <SwitchStatus channel={channel} />}
              <div className="date">{formatTimeStamp(channel?.created_at, 'DD MMM YYYY')}</div>
            </div>
            <div onClick={() => goToChannel(channel)}>
              <Typography.Title level={4} ellipsis={true} className="channelTitle">
                {channel?.title}
              </Typography.Title>

              <Typography.Paragraph ellipsis={{ rows: 2 }} className="channelDescription">
                {channel?.description}
              </Typography.Paragraph>
            </div>
          </div>
        )
      },


      {
        title: '',
        className: 'menuColumn  ',
        key: 'actions',
        //responsive: ['lg'],
        render: (channel: Channel) => (
          <div className="">

            {renderMenuItem(channel)}
            <RuleTag channel={channel} />

          </div>
        )
      }

    ],
    [ownChannel]
  );

  const renderMenuItem = (channel: Channel) => {
    return (
      <Dropdown
        className="actionsMenu"
        overlay={
          <Menu className="actionsMenu">

            {ownChannel &&
              <React.Fragment>
                <Menu.Item key="delete">
                  <Button type="link" className="linkBtn" onClick={() => deleteChannel(channel)}>
                    Delete
                  </Button>
                </Menu.Item>

                <Menu.Divider />
              </React.Fragment>
            }
            <Menu.Item key="share">
              <ShareComponent channelUUID={channel?.uuid}>
                <Button type="link" className="linkBtn">
                  Share
                </Button>
              </ShareComponent>
            </Menu.Item>

            <Menu.Divider />
            {
              channel.rule > 1 &&
              <Menu.Item key="edit">
                <Button type="link" className="linkBtn" onClick={() => goToChannel(channel)}>
                  Edit
                </Button>
              </Menu.Item>
            }
          </Menu>
        }
        trigger={['click']}>
        <EllipsisOutlined onClick={() => { }} />
      </Dropdown>
    );
  };
  const moveDraggable = (dragIndex, hoverIndex) => {
    if (!ownChannel) return false;
    const dragRow = tableData[dragIndex];
    setTableData((tableData) =>
      update(tableData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow]
        ]
      })
    );
    //debugger;
    ServiceChannels.order({
      channelUUID: dragRow?.uuid!,
      position: hoverIndex + 1
    });
  };

  const classes = classNames({ ownChannel, publicChannel: !ownChannel });

  if (!tableData.length) return <NoItems />;
  return (
    <React.Fragment>
      <div id="TableListWrapper">
        <DragAndDrop>
          <Table
            id="ChannelsList"
            dataSource={tableData}
            className={classes}
            rowKey="uuid"
            pagination={false}
            //@ts-ignore
            columns={columns}
            components={{
              body: {
                row: DraggableRow
              }
            }}
            //@ts-ignore
            onRow={(record, index) => ({
              index,
              moveRow: ownChannel ? moveDraggable : null
            })}
          />
        </DragAndDrop>
      </div>
    </React.Fragment>
  );
};
export default ChannelsList;
