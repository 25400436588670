import { Tag } from 'antd';
import React, { FC, useEffect, useMemo, useState } from "react";
import { Channel, RuleEnum } from 'store/types';
import COLORS from 'styles/Colors';

interface RuleTagProps {
  channel: Channel
}
const RuleTag: FC<RuleTagProps> = ({ channel }) => {

  const role = useMemo(() => {

    switch (channel?.rule) {

      case RuleEnum.CONSUMER:
        return "Consumer"
        break;

      case RuleEnum.COEDITOR:
        return "Coeditor"
        break;

      case RuleEnum.ADMIN:
        return "Admin"
        break;

      default:
        return "Guest"
    }


  }, [channel?.rule])

  if (channel.rule <= 0) return null
  return (
    <Tag className="RuleTag" color={COLORS.main}>You are {role}</Tag>
  )
}
export default RuleTag
