import CommonImage from 'components/commons/CommonImage';
import CommonTitle from 'components/commons/CommonTitle';
import EditableBox from 'components/commons/EditableBox';
import JsonLog from 'components/commons/JsonLog';
import UploadImage from 'components/commons/UploadImage';
import { pick } from 'lodash';
import React, { FC, useEffect, useRef, useState } from 'react';
import ServiceSpaces from 'services/ServiceSpaces';
import { BriefSpace } from 'store/types';

const cleanSpace = (inputChannel) => {
  return pick(inputChannel, ['company', 'description', 'image']);
};
interface SpaceDetailProps {
  space: BriefSpace;
  isMyPage?: boolean;
}
const SpaceDetail: FC<SpaceDetailProps> = ({ space, isMyPage = false, children }) => {
  const [image, setImage] = useState(undefined);

  //const [theSpace, setTheSpace] = useState<Partial<BriefSpace>>(undefined);

  const theSpace = useRef<Partial<BriefSpace>>(undefined);
  const setTheSpace = (newChannel) => {
    theSpace.current = newChannel;
  };
  useEffect(() => {
    if (space) {
      setTheSpace(cleanSpace(space));
    }
  }, [space]);

  const editCompany = (company) => {
    console.log('🚀 ~ editcompany ~ text', company);

    setTheSpace(cleanSpace({ ...space, company }));
  };

  const editDescription = (description) => {
    console.log('🚀 ~ editTitle ~ description', description);

    setTheSpace((space) => cleanSpace({ ...space, description }));
  };

  const changeImage = (image) => {
    setImage(image);
    console.log('🚀 ~ editTitle ~ description', image);
    const editSpace = { ...theSpace.current, image };
    setTheSpace((space) => cleanSpace(editSpace));
    updateSpace(editSpace);
  };

  const updateSpace = (editSpace: Partial<BriefSpace> = theSpace.current) => {
    const newSpace = cleanSpace({ ...theSpace.current, ...editSpace });
    console.log('🚀 ~ updateSpace ~ newSpace', newSpace);

    ServiceSpaces.update({
      spaceUUID: space.uuid,
      space: newSpace
    }).then((updated) => {
      console.log('🚀 ~ ServiceSpaces.update ~ updated', updated);
      setTheSpace(cleanSpace(updated));
    });
  };

  return (
    <React.Fragment>
      <CommonTitle level={3} className="mobile textCenter uppercase">
        {theSpace.current?.company}
      </CommonTitle>
      {isMyPage ? (
        <UploadImage
          type="full"
          className="channelImage bgCover "
          uploadImage={image || theSpace.current?.image}
          onUpload={(image) => {
            changeImage(image);
          }}
          enableCrop
        />
      ) : (
        <CommonImage image={theSpace.current?.image} />
      )}
      <div className="infoWrapper">
        <div className="channelInfos flexRow">
          <div className="fullWidth">
            <EditableBox

              id="channelTitle"
              placeholder="Add space Company"
              text={theSpace.current?.company}
              disabled={!isMyPage} // use true to disable edition
              onChanged={(newText) => newText && editCompany(newText)}
              onBlur={(company) => company && updateSpace({ company })}
            />

            <EditableBox

              id="channelDescription"
              text={theSpace.current?.description}
              placeholder="Add space description"
              disabled={!isMyPage} // use true to disable edition
              onChanged={(newText) => newText && editDescription(newText)}
              onBlur={(description) => description && updateSpace({ description })}
            />

            {/* <ShareComponent>
              <Button type="default" className="mobile shareButton">
                SHARE SPACE <SendOutlined />
              </Button>
            </ShareComponent> */}

            <JsonLog src={{ space, theSpace }} />
          </div>
          <div className="channelAdd">{children}</div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SpaceDetail;
